import qs from 'query-string';

export const Norstat = {
  NAME: 'Norstat',

  isPanelUser(queryString) {
    const paramKeys = Object.keys(qs.parse(queryString.toLowerCase()));
    return paramKeys.includes('source') && paramKeys.includes('state');
  },

  getPanelParams(queryString) {
    const parsed = qs.parse(queryString.toLowerCase());
    return { source: parsed.source, state: parsed.state };
  },
};
